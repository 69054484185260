import React, { Component } from "react";
import { API } from "../Lib";
import { format } from "d3-format";
import * as moment from "moment";
import { TimeSeries, Index } from "pondjs";
import { isEqual, isEmpty } from 'lodash';
import {
    Resizable,
    Charts,
    ChartContainer,
    ChartRow,
    YAxis,
    BarChart,
    styler
} from "react-timeseries-charts";
import ResponsiveWrapper from './ResponsiveWrapper';
import "./ChartStyle.css";

class ActivityTimeline extends Component {
    _isMounted = false;
    queryCounter = 0;
    constructor(props) {
        super(props);
        this.state = {
            "series": null,
            "accountIds": [],
            "maxValue": 0,
            "err": null
        };
    }

    async loadData(accountIds, start, end, userId) {
        this.queryCounter++;
        let counter = this.queryCounter;
        let query = await API.post("lambda", "/activity/timeseries", {
            body: {
                "accountId": accountIds,
                "tsEnd": end.valueOf(),
                "tsStart": start.valueOf(),
                "userId": userId
            }
        });
        if (counter !== this.queryCounter) {
            return;
        }
        return query;
    }

    getEnd() {
        let end;
        if (this.props.endTime) {
            end = moment(this.props.endTime);
        } else {
            end = moment();
        }
        return end;
    }

    getStart(end) {
        let start;
        if (this.props.timeSpan === 'week') {
            start = moment(end).subtract(1,'weeks');
        } else if (this.props.timeSpan === '6months') {
            start = moment(end).subtract(6, 'months');
        } else {
            start = moment(end).subtract(1, 'months');
        }
        return start;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
      }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (!isEqual(this.props.accountIds.sort(), prevProps.accountIds.sort())
            || this.props.user !== prevProps.user
            || !isEqual(this.props.timeSpan, prevProps.timeSpan)) {
          this.updateData();
        }
      }

    //React life cycle event, called when the component is placed into the React DOM (A.K.A mounted)
    updateData() {
        const end = this.getEnd();
        const start = this.getStart(end);
        this.loadData(this.props.accountIds, start, end, this.props.user ? this.props.user : null)
            .then((data) => {
                if (!this._isMounted || data === undefined) {
                    return;
                }
                let series = {
                    "name": "activity",
                    "columns": [ "index" ],
                    "points": []
                };
                if (!isEmpty(data.accountIds) && !isEmpty(data.timeseries)) {
                    let ids = data.accountIds.map((accountId) => {
                        return {
                            accountId: accountId,
                            key: accountId.replace(/\./gi, '_')
                        }
                    });
                    ids.forEach(item => {
                        series.columns.push(item.key);
                    });
                    let timeStep;
                    let timeStepAbbr;
                    if (this.props.timeSpan === 'week') {
                        timeStep = moment.duration(1,'day').asMilliseconds();
                        timeStepAbbr = "1d";
                    } else if (this.props.timeSpan === '6months') {
                        timeStep = moment.duration(1,'week').asMilliseconds();
                        timeStepAbbr = "7d";
                    } else {
                        timeStep = moment.duration(1,'day').asMilliseconds();
                        timeStepAbbr = "1d";
                    }
                    let i = 0;
                    let t = start;
                    let row;
                    let max = 0;
                    while (t <= end) {
                        row = [];
                        row[0] = Index.getIndexString(timeStepAbbr, new Date(t));
                        row[1] = moment(t).format();
                        for (let x = 1; x < data.timeseries[data.timeseries.length - 1].length; x++) {
                            row[x] = 0;
                        }
                        while (i < data.timeseries.length && data.timeseries[i][0] <= t) {
                            let rowTotal = 0;
                            for (let j = 1; j < data.timeseries[i].length; j++) {
                                rowTotal += data.timeseries[i][j];
                                row[j] = row[j] ? row[j] : 0 + data.timeseries[i][j];
                            }
                            i++;
                            if (rowTotal > max) {
                                max = rowTotal;
                            }
                        }
                        series.points.push(row);
                        t += timeStep;
                    }
                    this.setState({
                        "series": new TimeSeries(series),
                        "accountIds": ids,
                        "maxValue": max,
                        "err": null
                    });
                } else {
                    this.setState({
                        "series": null,
                        "accountIds": [],
                        "err": null,
                        "maxValue": -1});
                }
            }).catch(err => this.setState({ err }));
    }

    render() {
        const zoomStyle = {
            zoom: (this.props.parentWidth/2000*100)+'%'
        }
        const formatter = format(".2s");
        const yAxisFormat = (this.state.maxValue > 10 ? ".0f" : ".1f");
        const highlight = this.state.highlight;
        let infoValues = [];
        if (highlight) {
            const trafficText = `${formatter(highlight.event.get(highlight.column))}`;
            infoValues = [{ label: this.state.accountIds.find((el) => {return el.key === highlight.column}).accountId, value: trafficText }];
        }
        const style = styler(this.state.accountIds.map(el => {
                return {
                    key: el.key,
                    color: this.props.palette[el.key.replace(/_/gi, '.')]
                };
            }));
        let infoTimeFormat;
        if (this.props.timeSpan === '6months') {
            infoTimeFormat = function(index) { return "Week " + moment(index.begin()).format("W")};
        } else {
            infoTimeFormat = function(index) { return moment(index.begin()).format("Do MMM 'YY")};
        }
        if (this.state.series !== null) {
            return (
            <div className="ChartStyle">
                <h5>Number of events</h5>
                <Resizable style={zoomStyle}>
                    <ChartContainer timeRange={this.state.series.range()} >
                        <ChartRow height="150">
                            <YAxis
                                id="events"
                                label="Number of events"
                                min={0}
                                max={this.state.maxValue}
                                format={yAxisFormat}
                                width="70"
                                type="linear"
                                labelOffset={-15}
                            />
                            <Charts>
                                <BarChart
                                    axis="events"
                                    style={style}
                                    spacing={3}
                                    columns={this.state.accountIds.map(el => {return el.key})}
                                    series={this.state.series}
                                    info={infoValues}
                                    infoWidth={120}
                                    infoTimeFormat={index => infoTimeFormat(index)}
                                    highlighted={this.state.highlight}
                                    onHighlightChange={highlight =>
                                        this.setState({ highlight })
                                    }
                                />
                            </Charts>
                        </ChartRow>
                    </ChartContainer>
                </Resizable>
            </div>
            )
        } else if (this.state.err) {
            return (
                <div className="ChartStyle">
                    <h5>Number of events</h5>
                    <p>Error getting the data... Please try again!</p>
                </div>
            )
        } else {
            return (
                <div className="ChartStyle">
                    <h5>Number of events</h5>
                    <p>No data available</p>
                </div>
            )
        }
    }
};

export default ResponsiveWrapper(ActivityTimeline);