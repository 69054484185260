import React, { Component } from "react";
import { API } from "../Lib";
import * as moment from "moment";
import { isEqual, isEmpty } from 'lodash';
import { TimeSeries, Index } from "pondjs";
import {
    Resizable,
    Charts,
    ChartContainer,
    ChartRow,
    YAxis,
    AreaChart,
    styler
} from "react-timeseries-charts";
import "./ChartStyle.css";

import ResponsiveWrapper from './ResponsiveWrapper';

class UsageDataTimeline extends Component {
    _isMounted = false;
    queryCounter = 0;
    constructor(props) {
        super(props);
        this.state = {
            "series": null,
            "rawSeries" : null,
            "accountIds": [],
            "maxValue": 0,
            "err": null
        };
    }

    getEnd() {
        let end;
        if (this.props.endTime) {
            end = moment(this.props.endTime);
        } else {
            end = moment();
        }
        return end;
    }

    getStart(end) {
        let start;
        if (this.props.timeSpan === 'week') {
            start = moment(end).subtract(1,'weeks');
        } else if (this.props.timeSpan === '6months') {
            start = moment(end).subtract(6, 'months');
        } else {
            start = moment(end).subtract(1, 'months');
        }
        return start;
    }

    async loadData(accountIds, start, end) {
        this.queryCounter++;
        let counter = this.queryCounter;
        let query = await API.post("lambda", "/logfile/timeseries", {
            body: {
                "accountId": accountIds,
                "tsStart": start.valueOf(),
                "tsEnd": end.valueOf()
            }});
        if (counter !== this.queryCounter) {
            return;
        }
        return query;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
      }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (!isEqual(this.props.accountIds.sort(), prevProps.accountIds.sort())
            || this.props.user !== prevProps.user
            || !isEqual(this.props.timeSpan, prevProps.timeSpan)) {
            this.updateData();
        }
    }

    updateData() {
        const end = this.getEnd();
        const start = this.getStart(end);
        this.loadData(this.props.accountIds, start, end, this.props.timeSpan)
            .then((data) => {
                if (!this._isMounted || data === undefined) {
                    return;
                }
                let series = {
                    "name": "logs received",
                    "columns": [ "index"],
                    "points": []
                };
                if (!isEmpty(data.accountIds) && !isEmpty(data.timeseries)) {
                    let ids = data.accountIds.map((accountId) => (
                        {
                            accountId: accountId,
                            key: accountId.replace(/\./gi, '_')
                        }
                    ));
                    ids.forEach(item => series.columns.push(item.key));
                    let timeStep;
                    let timeStepAbbr;
                    if (this.props.timeSpan === 'week') {
                        timeStep = moment.duration(1,'day').asMilliseconds();
                        timeStepAbbr = "1d";
                    } else if (this.props.timeSpan === '6months') {
                        timeStep = moment.duration(1,'week').asMilliseconds();
                        timeStepAbbr = "7d";
                    } else {
                        timeStep = moment.duration(1,'day').asMilliseconds();
                        timeStepAbbr = "1d";
                    }
                    let i = 0;
                    let t = start;
                    let row;
                    let max = 0;
                    while (t <= end) {
                        row = [];
                        row[0] = Index.getIndexString(timeStepAbbr, new Date(t));
                        row[1] = moment(t).format();
                        for (let x = 1; x < data.timeseries[data.timeseries.length - 1].length; x++) {
                            row[x] = 0;
                        }
                        while (i < data.timeseries.length && data.timeseries[i][0] <= t) {
                            let rowTotal = 0;
                            for (let j = 1; j < data.timeseries[i].length; j++) {
                                rowTotal += data.timeseries[i][j];
                                row[j] = row[j] ? row[j] : 0 + data.timeseries[i][j];
                            }
                            i++;
                            if (rowTotal > max) {
                                max = rowTotal;
                            }
                        }
                        series.points.push(row);
                        t += timeStep;
                    }
                    this.setState({
                        "series": new TimeSeries(series),
                        "accountIds": ids,
                        "maxValue": max,
                        "rawSeries": series,
                        "err": null
                    });
                } else {
                    this.setState({
                        "series": null,
                        "accountIds": [],
                        "err": null,
                        "maxValue": -1});
                }
        }).catch(err => this.setState({ err }));
    }

    render() {
        if (this.state.err) {
            return (
                <div className="ChartStyle">
                    <h5 className="logs">Number of logs updated</h5>
                    <p>Error getting the data... Please try again!</p>
                </div>
            )
        } else if (this.state.series === null) {
            return (
                <div className="ChartStyle">
                    <h5 className="logs">Number of logs updated</h5>
                    <p>No data available</p>
                </div>
            )
        } else {
            const zoomStyle = {
                zoom: (this.props.parentWidth/2000*100)+'%'
            }
            const yAxisFormat = (this.state.maxValue > 10 ? ".0f" : ".1f");
            const interpolationType = "curveStepAfter";
            let infoValues = [];
            const style = styler(this.state.accountIds.map(el => {
                return {
                    key: el.key,
                    color: this.props.palette[el.key.replace(/_/gi, '.')]
                };
            }));

            let infoTimeFormat;
            if (this.props.timeSpan === '6months') {
                infoTimeFormat = function(index) { return "Week " + moment(index.begin()).format("W")};
            } else {
                infoTimeFormat = function(index) { return moment(index.begin()).format("Do MMM 'YY")};
            }

            return (
                <div className="ChartStyle">
                    <h5 className="logs">Number of logs updated</h5>
                    <Resizable style={zoomStyle}>
                        <ChartContainer timeRange={this.state.series.range()} >
                            <ChartRow height="150">
                                <YAxis
                                    id="usage"
                                    label="Logs uploaded (#)"
                                    min={0}
                                    max={this.state.maxValue}
                                    format={yAxisFormat}
                                    width="70"
                                    type="linear"
                                    labelOffset={-15}
                                />
                                <Charts>
                                <AreaChart
                                    axis="usage"
                                    style={style}
                                    fillOpacity={0.4}
                                    interpolation={interpolationType}
                                    columns={ {up:this.state.accountIds.map(el => {return el.key}), down:[]} }
                                    series={this.state.series}
                                    info={infoValues}
                                    infoWidth={120}
                                    infoTimeFormat={index => infoTimeFormat(index)}
                                />
                                </Charts>
                            </ChartRow>
                        </ChartContainer>
                    </Resizable>
                </div>
            );
        }
    }
}

export default ResponsiveWrapper(UsageDataTimeline);
