import React, { Component, Fragment } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import "./App.css";
import Routes from "./Routes";
import { LinkContainer } from "react-router-bootstrap";
import { Auth } from "aws-amplify";
import { API } from "./Lib";
import { SyncLoader } from 'react-spinners';
import * as _ from 'lodash';

class App extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      hasPendingRequests: false
    };

    API.callback = hasPendingRequests => {
      if (hasPendingRequests !== this.state.hasPendingRequests) {
        this.setState({ hasPendingRequests });
      }
    }

    API.errorCallback = error => {
      console.log("ErrorCallback", error.toString());
      if (_.includes(error.toString(), "Error: Network Error")) {
        this.userHasAuthenticated(false);
        this.props.history.push("/login");
      }
    }
  }
  
  async componentDidMount() {
    try {
      await Auth.currentAuthenticatedUser();
      this.userHasAuthenticated(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        this.userHasAuthenticated(false);
        this.props.history.push("/login");
        console.log(e);
      }
    }
  
    this.setState({ isAuthenticating: false });
  }
  
  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }
  
  handleLogout = async event => {
    await Auth.signOut();
    this.userHasAuthenticated(false);
    this.props.history.push("/login");
  }
  
  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      location: this.props.location
    };
  
    return (
      !this.state.isAuthenticating &&
      <div className="App container-flex">
        <Navbar fluid collapseOnSelect>
          <NavLink activeClassName="navbar-selected" className="navbar-left" to="/dashboard">
            Customer Dashboard
          </NavLink>
          <NavLink activeClassName="navbar-selected service-list" className="navbar-left service-list" to="/services">
            Service List
          </NavLink>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav pullRight>
              <NavItem>
                <SyncLoader
                  id="loader"
                  sizeUnit={"px"}
                  size={10}
                  color={'#8dcde0'}
                  loading={this.state.hasPendingRequests}
                />
              </NavItem>
              {this.state.isAuthenticated
                ? <NavItem onClick={this.handleLogout}>Logout</NavItem>
                : <Fragment>
                    <LinkContainer to="/login">
                      <NavItem>Login</NavItem>
                    </LinkContainer>
                  </Fragment>
              }
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Routes childProps={childProps} />
      </div>
    );
  }
}

export default withRouter(App);