import React from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "./containers/Dashboard";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import ServiceList from "./containers/ServiceList";
import { AliveRoute } from 'react-router-alive';
import AppliedRoute from "./components/AppliedRoute";

export default ({ childProps }) =>
  <div>
      { childProps.isAuthenticated ?
      <div>
        <AliveRoute path="/" exact component={Dashboard} />
        <AliveRoute path="/dashboard" exact component={Dashboard} />
        <AliveRoute path="/services" exact component={ServiceList} />
        <AliveRoute path="/login" exact component={Login} />
        { !(childProps.location.pathname.toString() === "/dashboard"
          || childProps.location.pathname.toString() === "/services"
          || childProps.location.pathname.toString() === "/login"
          || childProps.location.pathname.toString() === "/") ?
          <AliveRoute component={NotFound} /> : <div></div> }
      </div>
      :
      <div>
        <Switch>
          <AppliedRoute path="/" exact component={childProps.isAuthenticated ? Dashboard : Login} props={childProps} />
          <AppliedRoute path="/dashboard" exact component={childProps.isAuthenticated ? Dashboard : Login} props={childProps} />
          <AppliedRoute path="/login" exact component={Login} props={childProps} />
          <AppliedRoute path="/services" exact component={childProps.isAuthenticated ? ServiceList : Login} props={childProps} />
          { /* Finally, catch all unmatched routes */ }
          <Route component={NotFound} />
        </Switch>
      </div> }
  </div>