import React, { Component } from "react";
import { Button } from "react-bootstrap";
import "./Login.css";
import { Auth } from "aws-amplify";

import { withFederated } from 'aws-amplify-react';

import config from "../config";

const FederatedButtons = (props) => (
  <Button
    mt="1"
    style={{ width: '100%' }}
    onClick={props.googleSignIn}
    bsStyle="info">
    G-Suite
  </Button>
);

const Federated = withFederated(FederatedButtons);

const federated_data = {
    google_client_id: config.gsuite.CLIENT_ID,
    facebook_app_id: '',
    amazon_client_id: ''
};

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: ""
    };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
  
    this.setState({ isLoading: true });
  
    try {
      await Auth.signIn(this.state.email, this.state.password);
      this.props.userHasAuthenticated(true);
      this.props.history.push("/dashboard");
    } catch (e) {
      console.log(e.message);
      this.setState({ isLoading: false });
    }
  }

  onStateChangeUserHasAuthenticated(result) {
    if (result === "signedIn") {
      this.props.userHasAuthenticated(true);
      this.props.history.push("/dashboard");
    }
  }

  render() {
    return (
      <div className="Login">
        <div className="col-sm-3">
            <h4>Please login</h4>
            <Federated props={this.props} federated={federated_data} onStateChange={state => this.onStateChangeUserHasAuthenticated(state)} />
        </div>
      </div>
    );
  }
}