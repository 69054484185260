export const dataTemplate = [
{
    "name": "Spatineo Monitor",
    "section": "monitor",
    "children": [
        {
            "name": "Organize services",
            "size": 20,
            "section": "monitoring.organize_groups",
            "children": [
                {
                    "name": "Save",
                    "section": "monitoring.organize_groups.save",
                },
                {
                    "name": "Cancel",
                    "section": "monitoring.organize_groups.cancel",
                },
                {
                    "name": "Change default group",
                    "section": null,
                }
            ]
        },
        {
            "name": "Overview & Reports",
            "section": "overview",
            "children": [
                {
                    "name": "Dashboard",
                    "section": "overview.dashboard",
                    "children": [
                        {
                            "name": "Choose service",
                            "section": null,
                        },
                        {
                            "name": "Select page",
                            "section": null,
                        },
                        {
                            "name": "Choose meter",
                            "section": null,
                        }
                    ]
                },
                {
                    "name": "Reports",
                    "section": "overview.reports",
                    "children": [
                        {
                            "name": "Select report",
                            "section": null,
                        },
                        {
                            "name": "Delete report",
                            "section": "overview.reports.delete",
                        },
                        {
                            "name": "Download report",
                            "section": null,
                        },
                        {
                            "name": "Show scheduling",
                            "section": null,
                        },
                        {
                            "name": "Hide scheduling",
                            "section": null,
                        },
                        {
                            "name": "Select schedule",
                            "section": null,
                        },
                        {
                            "name": "Create schedule",
                            "section": "overview.reports.new",
                        },
                        {
                            "name": "Duplicate schedule",
                            "section": null,
                        },
                        {
                            "name": "Edit schedule",
                            "section": "overview.reports.update",
                        },
                        {
                            "name": "Run immediately",
                            "section": "overview.reports.run",
                        }
                    ]
                },
                {
                    "name": "Notification publisher",
                    "section": "overview.notifications",
                    "children": [
                        {
                            "name": "Remove notification",
                            "section": "overview.notifications.remove",
                        },
                        {
                            "name": "Notification dialog",
                            "section": "overview.notifications.edit",
                            "children": [
                                {
                                    "name": "Save notification",
                                    "section": "overview.notifications.save",
                                },
                                {
                                    "name": "Create notification",
                                    "section": null,
                                }
                            ]
                        },
                        {
                            "name": "Remove maintenance",
                            "section": null,
                        },
                        {
                            "name": "Maintenance dialog",
                            "section": null,
                            "children": [
                                {
                                    "name": "Edit maintenance",
                                    "section": null,
                                },
                                {
                                    "name": "Create maintenance",
                                    "section": null,
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            "name": "Change password",
            "section": null,
            "size": 10,
        },
        {
            "name": "Change language",
            "section": null,
            "size": 10,
        },
        {
            "name": "Logout",
            "section": null,
            "size": 10,
        },
        {
            "name": "Performance testing",
            "size": 10,
            "section": "performance",
        },
        {
            "name": "Monitoring & Usage",
            "section": "monitoring",
            "size": 200,
            "children": [
                {
                    "name": "Select group",
                    "section": null,
                },
                {
                    "name": "Search for service",
                    "section": null,
                },
                {
                    "name": "Select service",
                    "section": "monitoring.select_service",
                },
                {
                    "name": "Change display name",
                    "section": null,
                },
                {
                    "name": "Open capabilities",
                    "section": null,
                },
                {
                    "name": "Open Directory page",
                    "section": null,
                },
                {
                    "name": "Start following",
                    "section": null,
                },
                {
                    "name": "Stop following",
                    "section": null,
                },
                {
                    "name": "Edit groups",
                    "section": null,
                    "children": [
                        {
                            "name": "Save groups",
                            "section": null,
                        },
                        {
                            "name": "Cancel",
                            "section": null,
                        }
                    ]
                },
                {
                    "name": "Service info",
                    "section": "monitoring.info",
                    "children": [
                        {
                            "name": "Refresh service",
                            "section": null,
                        },
                        {
                            "name": "New / Stopped meter",
                            "section": "monitoring.info.new_or_stopped_meter",
                        },
                        {
                            "name": "Select layer",
                            "section": null,
                        },
                        {
                            "name": "Stop meter",
                            "section": null,
                        },
                        {
                            "name": "Advanced configuration dialog",
                            "section": "monitoring.info.service_settings",
                            "children": [
                                {
                                    "name": "Change advanced configuration",
                                    "section": "monitoring.info.service_settings.saved",
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Standards compliance",
                    "section": "monitoring.standards",
                    "children": [
                        {
                            "name": "Change conformance target",
                            "section": "monitoring.standards.validation_testsuites_choose",
                        },
                        {
                            "name": "Re-validate",
                            "section": "monitoring.standards.validation_start",
                        },
                        {
                            "name": "Print validation result",
                            "section": "monitoring.standards.validation_print",
                        },
                        {
                            "name": "Test result details",
                            "section": null,
                        },
                        {
                            "name": "Scroll to error",
                            "section": null,
                        }
                    ]
                },
                {
                    "name": "Usage analytics",
                    "section": "monitoring.analytics",
                    "children": [
                        {
                            "name": "Change request count type",
                            "section": null,
                        },
                        {
                            "name": "Toggle spatineo requests",
                            "section": null,
                        },
                        {
                            "name": "Change response time type",
                            "section": null,
                        },
                        {
                            "name": "Scroll back in time",
                            "section": null,
                        },
                        {
                            "name": "Scroll forward in time",
                            "section": null,
                        },
                        {
                            "name": "Change selected time period",
                            "section": null,
                        },
                        {
                            "name": "Zoom out",
                            "section": null,
                        },
                        {
                            "name": "Zoom to last day",
                            "section": null,
                        },
                        {
                            "name": "Zoom to last week",
                            "section": null,
                        },
                        {
                            "name": "Zoom to last month",
                            "section": null,
                        },
                        {
                            "name": "Change segment type",
                            "section": null,
                        },
                        {
                            "name": "Sort segment table",
                            "section": null,
                        },
                        {
                            "name": "Select segment",
                            "section": null,
                        },
                        {
                            "name": "Download segment data",
                            "section": "monitoring.analytics.download_analytics_segments",
                        },
                        {
                            "name": "Time panel",
                            "section": "monitoring.analytics.view.time",
                            "children": [
                                {
                                    "name": "Hover over time of day",
                                    "section": null,
                                },
                                {
                                    "name": "Hover over day of week",
                                    "section": null,
                                }
                            ]
                        },
                        {
                            "name": "User location panel",
                            "section": "monitoring.analytics.view.requestormap",
                            "children": [
                                {
                                    "name": "Hover over location cluster",
                                    "section": null,
                                },
                                {
                                    "name": "Zoom map",
                                    "section": null,
                                },
                                {
                                    "name": "Zoom to service bounds",
                                    "section": null,
                                }
                            ]
                        },
                        {
                            "name": "Request area panel",
                            "section": "monitoring.analytics.view.requestarea",
                            "children": [
                                {
                                    "name": "Hover over request area cluster",
                                    "section": null,
                                },
                                {
                                    "name": "Zoom map",
                                    "section": null,
                                },
                                {
                                    "name": "Zoom to service bounds",
                                    "section": null,
                                }
                            ]
                        },
                        {
                            "name": "Sort offering table",
                            "section": null,
                        },
                        {
                            "name": "Select offering",
                            "section": null,
                        },
                        {
                            "name": "Download offering data",
                            "section": "monitoring.analytics.download_analytics_offerings",
                        }
                    ]
                },
                {
                    "name": "Monitoring & Alerts",
                    "section": "monitoring.metrics",
                    "children": [
                        {
                            "name": "Change meter",
                            "section": null,
                        },
                        {
                            "name": "Change response time type",
                            "section": null,
                        },
                        {
                            "name": "Scroll back in time",
                            "section": null,
                        },
                        {
                            "name": "Scroll forward in time",
                            "section": null,
                        },
                        {
                            "name": "Change selected time period",
                            "section": null,
                        },
                        {
                            "name": "Zoom out",
                            "section": null,
                        },
                        {
                            "name": "Zoom to last day",
                            "section": null,
                        },
                        {
                            "name": "Zoom to last week",
                            "section": null,
                        },
                        {
                            "name": "Zoom to last month",
                            "section": null,
                        },
                        {
                            "name": "Download metering data",
                            "section": "monitoring.metrics.download_metrics",
                        },
                        {
                            "name": "Filter by response time",
                            "section": null,
                        },
                        {
                            "name": "Sort request table",
                            "section": null,
                        },
                        {
                            "name": "Show request details",
                            "section": null,
                        },
                        {
                            "name": "Alerts dialog",
                            "section": "monitoring.metrics.alerts",
                            "children": [
                                {
                                    "name": "Magic thresholds",
                                    "section": null,
                                },
                                {
                                    "name": "Change alert settings",
                                    "section": "monitoring.metrics.alerts.save",
                                }
                            ]
                        },
                        {
                            "name": "Select alert",
                            "section": null,
                        },
                        {
                            "name": "Edit alert notes",
                            "section": "monitoring.metrics.alert_note_save",
                        }
                    ]
                }
            ]
        }
    ]
}];