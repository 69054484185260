import React, {Component} from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import { FormControl, Button, Glyphicon } from 'react-bootstrap';
import { API } from "../Lib";
import "./ServiceList.css";
import * as _ from "lodash";
import { debounce } from 'lodash';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

const { ExportCSVButton } = CSVExport;

const dateFormatter = (num) => new Date(num).toLocaleString();

const availabilityFormatter = (num) => (isNaN(num)) ? num : num + "%";

function statusFormatter(cell) {
    return { fontWeight: (cell === '-' || cell === '...') ? 'normal' : 'bold', color: (cell === 'OK') ? 'green' : (cell === 'Down') ? 'red' : 'black'};
  }

function linkFormatter(cell) {
    let link = "https://directory.spatineo.com/service/" + cell + "/";
    return <a href={link} target="_blank" rel="noopener noreferrer">{cell}</a>;
}

const accountIdFormatter = (cell) => (cell === null) ? "" : cell;

const defaultSorted = [{
    dataField: 'serviceId', // if dataField is not match to any column you defined, it will be ignored.
    order: 'asc' // desc or asc
}];

const errorData = [{serviceId: "-", type: "-", currentVersion: "-", onlineResourceGetCapabilities: "Error getting the data, please try again!", title: "-", ownerAccountId: "-", canMeasure: "-", availability: "-"}];

export default class ServiceList extends Component {
    _isMounted = false;
    queryCounter = 0;
    constructor(props) {
        super(props)
        
        this.state = {
            data: [],
            availabilityData: [],
            sizePerPage: 30,
            page: 0,
            totalSize: null,
            error: "",
            searchText: null,
            sortField: null,
            sortOrder: null,
            rowSelection: [],
            toggledColumns: {
                row: true,
                serviceId: true,
                type: true,
                currentVersion:true,
                title: true,
                onlineResourceGetCapabilities: true,
                ownerAccountId: true,
                layerCount: true,
                canMeasure: true,
                status: true,
                availability: true,
                availabilityYear: true,
                insertedAtUtcMillis: true,
                updatedAtUtcMillis: true
            }
        }

        this.getData(0, 10, null, null, null);
        this.debouncedGetData = debounce(() => this.getData(this.state.page, this.state.sizePerPage, this.state.sortField, this.state.sortOrder, this.state.searchText), 400);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
      }

    async getData(page, sizePerPage, sortField, sortOrder, searchText) {
        try {
            this.queryCounter++;
            let counter = this.queryCounter;
            let query = await API.post('lambda', `/services/list`, { 
                body: { page: page, pageSize:sizePerPage, sort: { field: sortField, order: sortOrder }, query: searchText } });
            if (counter !== this.queryCounter) {
                return;
            }
            let data = query.results;
            this.currentData = data;
            data.forEach(service => {
                service.canMeasure = (service.serviceEnabled && service.robotsAllowsGetMap) ? true : false;
                service.availability = "...";
                service.status = "...";
                service.availabilityYear = "...";
            });
            this.getAvailability(data);
            if (this._isMounted) {
                this.setState({ data: this.formulateStateData(), rowSelection: [], totalSize: query.totalHits, page: query.page, sizePerPage: query.pageSize, sortField: sortField, sortOrder: sortOrder, error: null});
            }
        } catch(error) {
            if (this._isMounted) {
                this.setState({ error, data: errorData });
            }
        }
    }

    formulateStateData() {
        var ret = _.cloneDeep(this.currentData);
        _.each(ret, service => {
            if (this.availabilityCache[service.serviceId]) {
                service.availabilityYear = this.availabilityCache[service.serviceId].availabilityYear;
                service.availability = this.availabilityCache[service.serviceId].availability;
                service.status = this.availabilityCache[service.serviceId].status;
            } else {
                service.availabilityYear = '...';
                service.availability = '...';
                service.status = '...';
            }
        });
        return ret;
    }

    currentData = [];
    availabilityCache = {};

    getAvailability(data) {
        data.forEach(async service => {
            if (!this.availabilityCache[service.serviceId]) {
                try {
                    let result = await API.post('lambda', `/availability`, { body: { serviceIds: service.serviceId } });
                    let availability = "-";
                    let availabilityYear = "-";
                    let status = "";
                    if (result.availability !== null) {
                        availability = Math.round(100*Number(result.availability)*10)/10;
                    }
                    if (result.availabilityYear !== null) {
                        availabilityYear = Math.round(100*Number(result.availabilityYear)*10)/10;
                    }
                    if (result.ok === 1) {
                        status = "OK";
                    } else if (result.down === 1) {
                        status = "Down";
                    } else {
                        status = "-";
                    }
                    this.availabilityCache[service.serviceId] = {
                        availability: availability,
                        availabilityYear: availabilityYear,
                        status: status
                    }
                } catch(error) {
                    this.availabilityCache[service.serviceId] = {
                        availability: "Server error",
                        availabilityYear: "Server error",
                        status: "Server error"
                    }
                }
                if (this._isMounted) {
                    this.setState({ data: this.formulateStateData() });
                }
            }
        })
    }

    onReloadData() {
        this.currentData = [];
        this.availabilityCache = {};
        this.getData(this.state.page, this.state.sizePerPage, this.state.sortField, this.state.sortOrder, this.state.searchText);
    }

    sortStatus() {
        let sortedData = [...this.state.data];
        sortedData = sortedData.sort(function(a,b){
            if(a.status> b.status) return -1;
            if(a.status <b.status) return 1;
            if(a.availability> b.availability) return -1;
            if(a.availability <b.availability) return 1;
            return 0;
          });
        this.setState({ data: sortedData });
    }

    filterStatus() {
        let filteredData = [...this.state.data];
        let newRowSelection = [...this.state.rowSelection];
        filteredData = filteredData.filter(item => item.status !== "-");
        newRowSelection = newRowSelection.filter(item => filteredData.map(row => row.serviceId).includes(item));
        this.setState({ data: filteredData, rowSelection: [...new Set(newRowSelection)] });
    }

    filterSelected() {
        let filteredData = [...this.state.data];
        filteredData = filteredData.filter(item => this.state.rowSelection.includes(item.serviceId));
        this.setState({ data: filteredData });
    }

    onTextFilterChange(searchText) {
        if (this._isMounted) {
            this.setState({ searchText, page: 0 });
        }
        this.debouncedGetData();
    }

    onPageFilterChange(page) {
        page = page-1;
        if (this._isMounted) {
            this.setState({ page });
        }
        this.debouncedGetData();
    }

    onColumnToggleExtra(dataField) {
        let toggledColumns = this.state.toggledColumns;
        toggledColumns[dataField] = !this.state.toggledColumns[dataField]
        this.setState({ toggledColumns });
    }

    handleTableChange(type, props) {
        let page = props.page-1;
        let sizePerPage = props.sizePerPage;
        let sortField = props.sortField;
        let sortOrder = props.sortOrder;
        if (type === "pagination") {
            let firstOnPage = (this.state.sizePerPage*props.page)-(this.state.sizePerPage-1);
            page = Math.floor(firstOnPage/props.sizePerPage);
            page = (page === 0) ? 0 : page;
        }
        if (type === "sort") {
            page = 0;
        }
        this.getData(page, sizePerPage, sortField, sortOrder, this.state.searchText);
    }

    render() {
        const { sizePerPage, page, textFilter, rowSelection } = this.state;

        const columns = [{
            dataField: 'row',
            text: '#',
            sort: false,
            editable: false,
            formatter: (cell, row, rowIndex) => {
                const rowNumber = page * sizePerPage + (rowIndex + 1);
                return <span>{rowNumber}</span>;
                },
            csvExport: this.state.toggledColumns.row,
            csvFormatter: (cell, row, rowIndex) => {return page * sizePerPage + (rowIndex + 1)}
            },
            {
            dataField: 'serviceId',
            text: 'Service ID',
            sort: true,
            editable: false,
            formatter: linkFormatter,
            csvExport: this.state.toggledColumns.serviceId
            }, {
            dataField: 'type',
            text: 'Type',
            sort: false,
            editable: false,
            csvExport: this.state.toggledColumns.type
            }, {
            dataField: 'currentVersion',
            text: 'Version',
            sort: false,
            editable: false,
            csvExport: this.state.toggledColumns.currentVersion
            }, {
            dataField: 'title',
            text: 'Title',
            sort: false,
            editable: false,
            csvFormatter: accountIdFormatter,
            csvExport: this.state.toggledColumns.title
            }, {
            dataField: 'onlineResourceGetCapabilities',
            text: 'URL',
            sort: true,
            editable: false,
            csvExport: this.state.toggledColumns.onlineResourceGetCapabilities
            }, {
            dataField: 'ownerAccountId',
            text: 'Owner AccountId',
            sort: false,
            editable: false,
            csvFormatter: accountIdFormatter,
            csvExport: this.state.toggledColumns.ownerAccountId
            }, {
            dataField: 'layerCount',
            text: 'Layer Count',
            sort: true,
            editable: false,
            csvExport: this.state.toggledColumns.layerCount
            }, {
            dataField: 'canMeasure',
            text: 'Can measure',
            sort: false,
            editable: false,
            csvExport: this.state.toggledColumns.canMeasure
            }, {
            dataField: 'status',
            text: 'Status',
            style: statusFormatter,
            sort: false,
            editable: false,
            csvExport: this.state.toggledColumns.status
            }, {
            dataField: 'availability',
            text: 'Week',
            sort: false,
            editable: false,
            formatter: availabilityFormatter,
            csvFormatter: availabilityFormatter,
            csvExport: this.state.toggledColumns.availability
            }, {
            dataField: 'availabilityYear',
            text: 'Year',
            sort: false,
            editable: false,
            formatter: availabilityFormatter,
            csvFormatter: availabilityFormatter,
            csvExport: this.state.toggledColumns.availabilityYear
            }, {
            dataField: 'insertedAtUtcMillis',
            text: 'Inserted at',
            sort: false,
            editable: false,
            formatter: dateFormatter,
            csvFormatter: dateFormatter,
            csvExport: this.state.toggledColumns.insertedAtUtcMillis
            }, {
            dataField: 'updatedAtUtcMillis',
            text: 'Updated at',
            sort: true,
            editable: false,
            formatter: dateFormatter,
            csvFormatter: dateFormatter,
            csvExport: this.state.toggledColumns.updatedAtUtcMillis
        }];

        const CustomToggleList = ({
            columns,
            onColumnToggle,
            toggles
            }) => (
            <div className="btn-group btn-group-toggle" data-toggle="buttons">
                {
                columns
                    .map(column => ({
                    ...column,
                    toggle: toggles[column.dataField]
                    }))
                    .map(column => (
                    <button
                        type="button"
                        key={ column.dataField }
                        className={ `btn btn-sm btn-csv ${column.toggle ? 'active' : ''}` }
                        data-toggle="button"
                        aria-pressed={ column.toggle ? 'true' : 'false' }
                        onClick={ () => {onColumnToggle(column.dataField); this.onColumnToggleExtra(column.dataField); }}
                    >
                        { column.text }
                    </button>
                    ))
                }
            </div>
        );

        const fromItem = (sizePerPage*(page+1))-(sizePerPage-1);

        const toItem = (this.state.totalSize > (sizePerPage*(page+1))) ? (sizePerPage*(page+1)) : this.state.totalSize;

        const selectRow = {
            mode: 'checkbox',
            style: { background: 'rgba(6, 172, 214, 0.1)'},
            selected: rowSelection,
            onSelect: (row, isSelect, rowIndex, e) => {
                let newRowSelection = [...rowSelection];
                if (isSelect === true) {
                    newRowSelection.push(row.serviceId);
                    this.setState({ rowSelection: [...new Set(newRowSelection)] });
                } else {
                    newRowSelection = newRowSelection.filter(item => item !== row.serviceId);
                    this.setState({ rowSelection: [...new Set(newRowSelection)] });
                }
            },
            onSelectAll: (isSelect, rows, e) => {
                let newRowSelection = [...rowSelection];
                if (isSelect === true) {
                    newRowSelection = newRowSelection.concat(rows.map(row => row.serviceId));
                    this.setState({ rowSelection: [...new Set(newRowSelection)] });
                } else {
                    this.setState({ rowSelection: [] });
                }
            }
        };

        return (
            <div className= "ServiceList">
                <div className="bootstrap-table">
                    <PaginationProvider
                        pagination={ paginationFactory({
                            page: page+1,
                            sizePerPage: sizePerPage,
                            totalSize: this.state.totalSize,
                            sizePerPageList: [ {text: '10', value: 10}, {text: '25', value: 25}, {text: '30', value: 30}, {text: '50', value: 50}, {text: '100', value: 100}, {text: '500', value: 500} ],
                            hidePageListOnlyOnePage: true }) }
                    >
                        { ({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                                keyField="serviceId"
                                data={ this.state.data }
                                columns={ columns }
                                columnToggle
                                exportCSV={ { fileName: "service_list.csv" } }
                            >
                                { props => (
                                    <div>
                                        <div className= "TextSearch">
                                            <div className="text-search-left">
                                                <h4>Service List</h4>
                                                <h5>({fromItem} - {toItem} / {this.state.totalSize})</h5>
                                            </div>
                                            <div className="text-search-right">
                                                <h5>Search</h5>
                                                <FormControl
                                                    type="text"
                                                    value={textFilter}
                                                    onChange={event => this.onTextFilterChange(event.target.value)}
                                                    placeholder="Text filter"
                                                    bsSize="small"
                                                    id="text-search-filter"/>
                                                <Button className= "text-search-button" onClick={() => this.onReloadData()}>
                                                    <Glyphicon id="text-search-glyphicon" glyph='refresh'></Glyphicon>
                                                </Button>
                                                <Button className= "text-search-button" onClick={() => this.sortStatus()}>
                                                    Sort Status
                                                </Button>
                                                <Button className= "text-search-button" onClick={() => this.filterStatus()}>
                                                    Filter Status
                                                </Button>
                                                <Button className= "text-search-button" onClick={() => this.filterSelected()}>
                                                    Filter Selected
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="columns-and-pagination">
                                            <CustomToggleList { ...props.columnToggleProps } />
                                            <div className="size-per-page-pagination">
                                                <SizePerPageDropdownStandalone { ...paginationProps } />
                                                <PaginationListStandalone { ...paginationProps } />
                                            </div>
                                        </div>
                                        <BootstrapTable
                                            { ...props.baseProps }
                                            remote
                                            id='services'
                                            keyField='serviceId'
                                            data={ this.state.data }
                                            page={ page+1 }
                                            sizePerPage={ sizePerPage }
                                            totalSize={ this.state.totalSize }
                                            onTableChange={ this.handleTableChange.bind(this) }
                                            columns={ columns }
                                            defaultSorted={ defaultSorted }
                                            selectRow={ selectRow }
                                            pagination={ paginationFactory({
                                                page: page+1,
                                                sizePerPage: sizePerPage,
                                                totalSize: this.state.totalSize,
                                                sizePerPageList: [ {text: '10', value: 10}, {text: '25', value: 25}, {text: '30', value: 30}, {text: '50', value: 50}, {text: '100', value: 100}, {text: '500', value: 500} ],
                                                hidePageListOnlyOnePage: true }) }
                                            { ...paginationTableProps }
                                        />
                                        <div className="bottom-buttons">
                                            <ExportCSVButton { ...props.csvProps }>Export CSV</ExportCSVButton>
                                            <div className="jump-to-page">
                                                { (this.state.totalSize > sizePerPage) ?
                                                    <h5>Jump to page: </h5> : <span></span>
                                                }
                                                { (this.state.totalSize > sizePerPage) ?
                                                    <FormControl
                                                        type="number"
                                                        value={ page + 1 }
                                                        onChange={event => this.onPageFilterChange(Number(event.target.value))}
                                                        placeholder="Page"
                                                        bsSize="small"
                                                        id="page-search-filter"
                                                    /> : <span></span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ) }
                            </ToolkitProvider>
                        ) }
                    </PaginationProvider>
                </div>
            </div>
        );
    }
}
