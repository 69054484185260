import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Button } from 'react-bootstrap';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import * as _ from 'lodash';

import {API} from "../Lib";

import "./AccountList.css";

class ExpectedLogsIn30DaysEditor extends React.Component {
  static defaultProps = {
    value: 0
  }
  getValue() {
    return parseInt(this.input.value, 10);
  }
  render() {
    const { value, onUpdate, ...rest } = this.props;
    return [
      <p key="label">Enter expected amount of logs in 30 days</p>,
      <input
        { ...rest }
        key="range"
        ref={ node => this.input = node }
        type="number"
      />
    ];
  }
}

export default class AccountList extends React.Component {
    constructor(props) {
        super(props);

        this.onRowSelect = this.onRowSelect.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);

        this.state = {
            logUploadStatus: {},
            availabilityStatus: {},
            rows: this.rowsFromProps(),
            errorEdit: null
        };
    }

    componentDidUpdate(prevProps) {
        var prevAccountIds = _.map(prevProps.results, r => r.accountId);
        var currAccountIds = _.map(this.props.results, r => r.accountId);
        if (!_.isEqual(prevAccountIds, currAccountIds)) {
            this.setState({ rows: this.rowsFromProps() });
        }
    }

    rowsFromProps() {
        return _.map(this.props.results, r => { return {
            accountId: r.accountId,
            serviceUp: this.serviceUp(r),
            availability: this.availability(r),
            logUpload: this.logUpload(r)
        }});
    }

    serviceUp(row) {
        const availabilityStatus = this.state.availabilityStatus;
        var ret = availabilityStatus[row.accountId];
        if (ret) {
            return ret.serviceUp;
        }

        // Set it to "pending", no need to update state
        availabilityStatus[row.accountId] = { serviceUp: '...', availability: '...' };

        var serviceIds = _.map(row.services, s => s.wmsServiceID);

        API.post('lambda', `/availability`, { body: { serviceIds: serviceIds } })
            .then(result => {
                var rows = this.state.rows;
                var thisRow = _.find(rows, r => r.accountId === row.accountId) || {};
                availabilityStatus[row.accountId].serviceUp = thisRow.serviceUp = result.ok+'/'+(result.ok+result.notOk);
                availabilityStatus[row.accountId].availability = thisRow.availability = Math.round(100*result.availability*10)/10;

                this.setState({ availabilityStatus, rows });
            }, error => {
                availabilityStatus[row.accountId] = { serviceUp: 'Server Error', availability: 'Server Error' };
                console.log('Error loading availability for '+row.accountId, error);
                this.setState({ availabilityStatus, rows: this.rowsFromProps() });
            });

        return '...';
    }

    availability(row) {
        const availabilityStatus = this.state.availabilityStatus;
        var ret = availabilityStatus[row.accountId];
        if (ret) {
            return ret.availability;
        }
        return '...';
    }

    logUpload(row) {
        const logUploadStatus = this.state.logUploadStatus;
        var ret = logUploadStatus[row.accountId];
        if (ret) {
            return ret;
        }

        // Set it to "pending", no need to update state
        logUploadStatus[row.accountId] = '...';

        API.get('lambda', `/account/${row.accountId}/logfile_status`)
            .then(result => {
                var rows = this.state.rows;
                var thisRow = _.find(rows, r => r.accountId === row.accountId) || {};
                logUploadStatus[row.accountId] = thisRow.logUpload = result.count + '/' + (result.expectedLogsIn30Days !== null ? result.expectedLogsIn30Days : '-');
                this.setState({ logUploadStatus, rows });
            }, error => {
                logUploadStatus[row.accountId] = 'Server Error';
                console.log('Error loading log upload status for '+row.accountId, error);
                this.setState({ logUploadStatus, rows: this.rowsFromProps() });
            });
        return '...';
    }

    onRowSelect(row, isSelected) {
        let selected = [...this.props.selected]
        if (isSelected === true) {
            selected.push(row.accountId);
        } else {
            selected = selected.filter(account => account !== row.accountId);
        }
        this.props.changeAccountIdSelection(selected);
    }
    
    onSelectAll(isSelected, rows) {
        let selected = [...this.props.selected];
        if (isSelected) {
            for (let i = 0; i < rows.length; i++) {
                selected.push(rows[i].accountId);
            }
        } else {
            for (let i = 0; i < rows.length; i++) {
                selected = selected.filter(account => account !== rows[i].accountId);
            }
        }
        this.props.changeAccountIdSelection(selected);
    }

    updateLogUploadColumn(oldValue, newValue, row, column) {
        this.updateExpectedLogUploadCount(row.accountId, newValue);
    }

    updateExpectedLogUploadCount(accountId, newCount) {
        const logUploadStatus = this.state.logUploadStatus;
        const rows = this.state.rows;

        var row = _.find(rows, r => r.accountId === accountId) || {};

        logUploadStatus[accountId] = null;
        row.logUpload = '...';

        this.setState({ logUploadStatus, rows: this.state.rows });

        API.put('lambda', `/account/${accountId}/preferences`, { body: { accountId, expectedLogsIn30Days: newCount }}).then( () => {
            this.logUpload( { accountId });
        }, errorEdit => {this.setState({ errorEdit })});
    }

    render() {
        const selectRowProp = {
            mode: "checkbox",
            clickToSelect: false,
            clickToEdit: true,
            bgColor: "rgb(194, 228, 237)",
            selected: this.props.selected,
            onSelect: this.onRowSelect,
            onSelectAll: this.onSelectAll
        };
        const formatter = (num) => num + "%";
        const columns = [{
            dataField: 'accountId',
            text: 'Account ID',
            sort: true,
            editable: false,
            events: {onClick: (e) => {
                let selected = [];
                selected.push(e.target.innerText);
                this.props.changeAccountIdSelection(selected);
            }}
          }, {
            dataField: 'serviceUp',
            text: 'Services up now',
            sort: true,
            editable: false
          }, {
            dataField: 'availability',
            text: 'Availability last 7 days',
            sort: true,
            editable: false,
            formatter: formatter
          }, {
            dataField: 'logUpload',
            text: 'Logs uploaded / expected / month',
            sort: true,
            editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => {
                var expectedLogsIn30Days = 0;
                var idx = value.indexOf('/');
                var tmp;
                if (idx !== -1) {
                    tmp = parseInt(value.substring(idx+1), 10);
                    if (_.isNumber(tmp) && _.isFinite(tmp)) {
                        expectedLogsIn30Days = tmp;
                    }
                }
                editorProps.defaultValue = expectedLogsIn30Days;
                return(
                    <ExpectedLogsIn30DaysEditor { ...editorProps } value={ expectedLogsIn30Days } />
                );
            }
          }];
          
        return(
            <div className="AccountList">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <h4>Account List ({this.props.selected.length}/{this.props.results.length})</h4>
                            </td>
                            <td className="empty-selection">
                                <h4><Button id="empty-button" bsSize= "xsmall" bsStyle="info" onClick={() => this.props.changeAccountIdSelection([])}>Empty selection</Button></h4>
                            </td>
                        </tr>
                    </tbody>
                </table>
                {(this.state.errorEdit) ? <p className="error">Error updating the "Logs uploaded" field... Please try again!</p> : null}
                {(this.props.err) ? <p className="error">Error loading the accounts... Please try again!</p> :
                <BootstrapTable
                    id='accounts'
                    keyField='accountId'
                    data={ this.state.rows }
                    columns={ columns }
                    selectRow={ selectRowProp }
                    cellEdit={ cellEditFactory({
                        mode: 'dbclick',
                        afterSaveCell: this.updateLogUploadColumn.bind(this)
                    })}
                    pagination={ paginationFactory({hidePageListOnlyOnePage: true}) } />
                }
            </div>
        )
    }
}