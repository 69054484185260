import React, { Component } from "react";
import { Legend, styler } from "react-timeseries-charts";
import "./ChartLegend.css";

export default class ChartLegend extends Component {

    render() {
        if (!!this.props.accountIds.length) {
            const legendCategories = this.props.accountIds.map(el => ({ key: el, label: el }));
            const style = styler(this.props.accountIds.map(el => {
                return {
                    key: el,
                    color: this.props.palette[el]
                };
            }));
        
            return (
                <div className="ChartLegend">
                    <Legend
                        categories={legendCategories}
                        style={style}
                        align="right"
                        symbolWidth={16}
                        symbolHeight={16}
                        type="swatch"
                        marginBottom="0px"
                    />
                </div>
            );
        } else {
            return (
                <div className="ChartLegend"> </div>
            );
        }


    }
}