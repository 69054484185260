import {API as AmplifyAPI } from "aws-amplify";
import Amplify from "aws-amplify";
import config from "./config";
import * as moment from "moment";

Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      identityPoolId: config.cognito.IDENTITY_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID
    },
    API: {
      endpoints: [
        {
          name: "lambda",
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION
        }
      ]
    }
  });

let requestCounter = 0;
let requestBoolean = false;

function calculatePromise(promise) {
    let start = moment();
    API.updateCounter(1);
    promise.then(function(result) {
        API.updateCounter(-1);
    }, function(err) {
        API.updateCounter(-1);
        let end = moment();
        if (end-start > 28000) {
            API.errorCallback("Error: Timeout");
        } else {
            API.errorCallback(err);
        }
    });
    return promise;
}

function postToAPInoAsync(...rest) {
    return calculatePromise(AmplifyAPI.post(...rest));
}

function getToAPInoAsync(...rest) {
    return calculatePromise(AmplifyAPI.get(...rest));
}

function putToAPInoAsync(...rest) {
    return calculatePromise(AmplifyAPI.put(...rest));
}

export const API = {
    post: postToAPInoAsync,
    get: getToAPInoAsync,
    put: putToAPInoAsync,
    updateCounter: function (num) {
        let requestHelper = requestBoolean;
        requestCounter = requestCounter + num;
        if (requestCounter > 0) {
            requestBoolean = true;
        } else {
            requestBoolean = false;
        }
        if (typeof this.callback === 'function' && requestBoolean !== requestHelper) this.callback(requestCounter > 0);
    },
    errorCallback: function (error) {
        if (typeof this.callback === 'function') this.callback(error);
    }
};

