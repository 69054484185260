import React from 'react';
import { Button, DropdownButton, MenuItem, FormControl, Checkbox } from 'react-bootstrap';
import { debounce } from 'lodash';
import "./AccountFilter.css";

export default class AccountFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = { accountManager: '', accountType: '', textFilter: '', enableAccount: true };

        this.accountManagerItems = [
            'all',
            'fabio.bittencourt@spatineo.com',
            'jaana.makela@spatineo.com',
            'luukas.raatikainen@spatineo.com',
            'sampo.savolainen@spatineo.com',
            'tapio.siltala@spatineo.com'
        ];

        this.accountTypeItems = [ 'all', 'trial', 'customer', 'partner' ];
        
        this.enableAccount = true;
        
        this.debouncedFetchResults = debounce(() => this.props.fetchResults(this.state), 200);
    }

    onAccountManagerChange(value) {
        this.setState({ accountManager: value }, () => {
            this.props.fetchResults(this.state);
        });
    }

    onAccountTypeChange(value) {
        this.setState({ accountType: value }, () => {
            this.props.fetchResults(this.state);
        });
    }
    
    onTextFilterChange(value) {
        this.setState({ textFilter: value });
        this.debouncedFetchResults();
    }
    
    onEnableAccountChange(value) {
        this.setState({ enableAccount: value });
        this.debouncedFetchResults();
    }
    
    renderAccountManagerMenuItem(item) {
        return <MenuItem onSelect={() => this.onAccountManagerChange(item)} key={item}>{item}</MenuItem>;
    }
    
    renderAccountTypeMenuItem(item) {
        return <MenuItem onSelect={() => this.onAccountTypeChange(item)} key={item}>{item}</MenuItem>;
    }
    
    render() {
        return (
            <div className="AccountFilter">
                <h4>Account Filter</h4>
                <table>
                    <tbody>
                        <tr>
                            <td>Account Manager</td>
                            <td><DropdownButton bsSize="small" title={this.state.accountManager || '-- Select One --'} id="accountManager-dropdown" >
                                    {this.accountManagerItems.map(item => this.renderAccountManagerMenuItem(item))}
                                </DropdownButton>
                            </td>
                        </tr>
                        <tr>
                            <td>Account Type</td>
                            <td><DropdownButton bsSize="small" title={this.state.accountType || '-- Select One --'} id="accountType-dropdown">
                                    {this.accountTypeItems.map(item => this.renderAccountTypeMenuItem(item))}
                                </DropdownButton>
                            </td>
                        </tr>
                        <tr>
                            <td>Text filter</td>
                            <td><FormControl
                                type="text"
                                value={this.state.textFilter}
                                onChange={event => this.onTextFilterChange(event.target.value)}
                                placeholder="Text filter"
                                bsSize="small"
                                id="AccountFilterTextFilter"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                <Checkbox 
                                    checked={this.state.enableAccount}
                                    onChange={event => this.onEnableAccountChange(event.target.checked)}>
                                    Enabled accounts only
                                </Checkbox>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Button bsStyle="info" onClick={() => this.props.fetchResults(this.state)}>Search</Button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

