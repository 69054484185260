export default {
    apiGateway: {
      REGION: "eu-west-1",
      URL: "https://ue3zyz253i.execute-api.eu-west-1.amazonaws.com/prod"
    },
    cognito: {
      REGION: "eu-west-1",
      USER_POOL_ID: "eu-west-1_ViTbaRyqG",
      APP_CLIENT_ID: "74jqkpih7s1g0a48ln8b0cjoj3",
      IDENTITY_POOL_ID: "eu-west-1:75e5ed19-204a-414c-bf33-c755dfccceb2"
    },
    gsuite: {
      CLIENT_ID: "1017682508866-ti9db9ue7081e512d2dc96agprk3gpv0.apps.googleusercontent.com"
    }
  };