import React, { Component } from "react";
import ResponsiveWrapper from './ResponsiveWrapper';
import { API } from "../Lib";
import * as _ from "lodash";
import * as moment from "moment";
import ReactEcharts from 'echarts-for-react';
import echarts from 'echarts';
import './ActivityHeatMap.css';
import  { dataTemplate } from './ActivityHeatMapDataTemplate';

export class ActivityHeatMap extends Component {
    queryCounter = 0;
    constructor(props) {
        super(props);
        
        this.state = {
            heatmap: [],
            finalData: [],
            colorMax: null,
            err: null
        };
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.accountIds, this.props.accountIds) ||
            prevProps.user !== this.props.user ||
            prevProps.timeSpan !== this.props.timeSpan) {
            this.updateData(this.props.accountIds, this.props.user, this.props.timeSpan)
        }
    }

    async updateData(accountIds, selectedUser, timeSpan) {
        try {
            let start, end = moment();
            if (timeSpan === 'week') {
                start = moment(end).subtract(1,'weeks');
            } else if (timeSpan === '6months') {
                start = moment(end).subtract(6, 'months');
            } else {
                start = moment(end).subtract(1, 'months');
            }

            this.queryCounter++;
            let counter = this.queryCounter;

            var response = await API.post('lambda', '/activity/heatmap', { body: { 
                accountId: accountIds,
                userId: selectedUser,
                tsStart: start.valueOf(),
                tsEnd: end.valueOf() } });

            if (counter !== this.queryCounter) {
                return;
            }

            const translateLabels = {
                'Organize Groups Dialog':        'monitoring.organize_groups',
                'Organize Groups Save':          'monitoring.organize_groups.save',
                'Organize Groups Cancel':        'monitoring.organize_groups.cancel',

                'ValidationTestSuites Choose':   'monitoring.standards.validation_testsuites_choose',
                'Validation Start':              'monitoring.standards.validation_start',
                'Validation Print':              'monitoring.standards.validation_print',

                'Select Service':                'monitoring.select_service',

                'Download Metrics':              'monitoring.metrics.download_metrics',

                'Alert Note Saved':              'monitoring.metrics.alert_note_save',

                'Alert Config Dialog':           'monitoring.metrics.alerts',
                'Alert Config Save':             'monitoring.metrics.alerts.save',
                'Alert Config Cancel':           'monitoring.metrics.alerts.cancel',

                'MeterConfig Dialog':            'monitoring.info.meter_dialog.open',
                'MeterConfig Submit':            'monitoring.info.new_or_stopped_meter',

                'ServiceConfig Dialog':          'monitoring.info.service_settings',
                'ServiceConfig Write':           'monitoring.info.service_settings.saved',

                'Download Analytics: Segments':  'monitoring.analytics.download_analytics_segments',
                'Download Analytics: Offerings': 'monitoring.analytics.download_analytics_offerings',


                'Reports Remove':                'overview.reports.delete',
                'Reports Save':                  'overview.reports.new',
                'Reports Update':                'overview.reports.update',
                'Reports Run':                   'overview.reports.run',

                'Notifications Edit':            'overview.notifications.edit',
                'Notifications Save':            'overview.notifications.save',
                'Notifications Remove':          'overview.notifications.remove'
            }

            function normalizeLabel(l) {
                l = translateLabels[l] || l;
                l = l.toLowerCase();
                // replace all " / " to .
                l = l.replace(/ \/ /g, '.');
                l = l.replace(/monitoring.analytics.view: /, 'monitoring.analytics.view.');
                // remove whitespace
                l = l.replace(/\s/g, '');
                return l;
            }

            _.each(response.activity, a => { if (_.isArray(a.section)) { a.section = a.section[0]; } a.section = normalizeLabel(a.section); });
            let finalData = this.getData(response.activity);
            let colorMax = Math.max.apply(Math, response.activity.map(function(o) { return o.value; }));
            this.setState({ heatmap: response.activity, colorMax: colorMax, finalData: finalData, err: null });
        } catch(err) {
            this.setState({ err });
        }
    }
    
    getSaturation = (value, colorMax) => {
        let option = this.getOption();
        let max =colorMax;
        let colorSaturationMin = option.series[0].colorSaturation[0];
        let colorSaturationMax = option.series[0].colorSaturation[1];
        const logSteepness = 600;
        let saturation = Math.log10(value/max * logSteepness + 1) / Math.log10(logSteepness + 1);
        saturation = colorSaturationMin + saturation*(colorSaturationMax - colorSaturationMin);
        return saturation;
    };
    
    getValue = (section, response) => {
        let value = response.filter(obj => {
            return obj.section === section});
        value = (value.length === 0) ? 0 : value[0].value;
        return value;
    }
    
    getExtraData = (service, response) => {
        let colorMax = Math.max.apply(Math, response.map(function(o) { return o.value; }));
        let value = [];
        let itemStyle = {};
        let label = {};
        let upperLabel = {};
        let emphasis = {};
        if (service.section == null) {
            value = [0, 0];
        } else {
            value = [(service.hasOwnProperty('size')) ? service.size : 100, 
                    this.getValue(service.section, response)];
            if (value[1] === 0) {
                itemStyle = {
                    "color": "#f0f0f0",
                    "colorSaturation": 0.95,
                    "borderColor": "#f0f0f0",
                    "borderColorSaturation": 0.9
                }
                label = {
                    "color": "#777"
                }
                upperLabel = {
                    "color": "#777",
                    "fontWeight": "bold"
                }
                emphasis = {
                    "upperLabel": {
                        "color": "#777",
                        "fontWeight": "bold"
                    }
                }
            } else {
                itemStyle = {
                    "color": "#355b72",
                    "colorSaturation": this.getSaturation(value[1], colorMax),
                    "borderColor": "#355b72",
                    "borderColorSaturation": this.getSaturation(value[1], colorMax),
                }
            }
        }
        service["value"] = value;
        service["itemStyle"] = itemStyle;
        service["label"] = label;
        service["upperLabel"] = upperLabel;
        service["emphasis"] = emphasis;
        return service;
    }

    getData = (response) => {
        let data = dataTemplate.slice(0);
        let that = this;
        function calculateExtraData(child, response) {
            that.getExtraData(child, response);
            if (child.hasOwnProperty('children')) {
                child.children.forEach(child => calculateExtraData(child, response));
            }
        }
        data.forEach(service => calculateExtraData(service, response));
        return data;
    }
    
    getOption = () => {
        var formatUtil = echarts.format;
        function getLevelOption() {
            return [
                {
                    itemStyle: {
                        color: "#fff",
                        colorSaturation: 1,
                        borderColor: "#fff",
                        borderColorSaturation: 1,
                        borderWidth: 0,
                        gapWidth: 4
                    },
                    upperLabel: {
                        normal: {
                            show: false
                        },
                        color: "#777",
                        fontWeight: "bold"
                    },
                    emphasis: {
                        upperLabel: {
                            normal: {
                                show: false
                            },
                            color: "#777",
                            fontWeight: "bold"
                        }
                    }
                },
                {
                    itemStyle: {
                        borderWidth: 5,
                        gapWidth: 4,
                    }
                },
                {
                    itemStyle: {
                        borderWidth: 3,
                        gapWidth: 4,
                    }
                },
                {
                    itemStyle: {
                        borderWidth: 3,
                        gapWidth: 4,
                    }
                },
                {
                    itemStyle: {
                        borderWidth: 3,
                        gapWidth: 4,
                    }
                }
          ];
        }

        const option = {
            tooltip: {
                formatter: function (info) {
                var value = info.value;
                var treePathInfo = info.treePathInfo;
                var treePath = [];

                for (var i = 1; i < treePathInfo.length; i++) {
                    treePath.push(treePathInfo[i].name);
                }

                return [
                    '<div class="tooltip-title">' + info.name + '</div>',
                    'Clicks: ' + formatUtil.addCommas(value[1]),
                ].join('');
                }
            },
            series: [{
                colorSaturation: [0.65, 0.35],
                visualMin: 0,
                visualMax: this.state.colorMax,
                visualDimension: 1,
                name: 'Spatineo',
                type:'treemap',
                width: '100%',
                height: '90%',
                top: 0,
                nodeClick: false,
                roam:false,
                squareRatio: 2,
                label: {
                    show: true,
                    formatter: '{b}',
                    fontSize:11,
                    padding: 1,
                    fontFamily: 'Open Sans'
                },
                upperLabel: {
                    normal: {
                        show: true,
                        height: 20,
                        padding: 3,
                        fontSize:11,
                        fontFamily: 'Open Sans',
                    }
                },
                breadcrumb: {
                    show: false
                },
                levels: getLevelOption(),
                data: this.state.finalData,
            }]
        }
        return option;
    };

    render() {
        const values = {};

        _.each(this.state.heatmap, h => {
            var parts = h.section.split('.');
            var tmp = null;
            _.each(parts, p => {
                if (tmp === null) {
                    tmp = p;
                } else {
                    tmp += '.'+p;
                }
                var value = values[tmp] || 0;
                value += h.value;
                values[tmp] = value;
            });
        });
        return (
            <div className="ActivityHeatMap">
            <table>
                <tbody>
                <tr>
                    <td><h5>Heatmap</h5></td>
                    <td className="info"><h6><strong>Color:</strong> Darker the color, more usage <strong>Grayish:</strong> Not used <strong>Removed:</strong> Data not collected</h6></td>
                </tr>
                </tbody>
            </table>
            {(this.state.err) ? <p className="error">Error getting the data... Please try again!</p> :
                <div>
                    <ReactEcharts
                        option={this.getOption()}
                        style={{height: '500px', width: '100%'}}
                        className='react_for_echarts' />
                </div>
            }
            </div>
        );

        // Missing: monitoring.sharing (widgets)
    }
};


export default ResponsiveWrapper(ActivityHeatMap);
