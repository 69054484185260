import React, {Component} from "react";
import { API } from "../Lib";
import "./Dashboard.css";
import AccountFilter from "../components/AccountFilter";
import AccountList from "../components/AccountList";
import UsageDataTimeline from "../components/UsageDataTimeline";
import ActivityTimeline from "../components/ActivityTimeline";
import ActivityHeatMap from "../components/ActivityHeatMap";
import { DropdownButton, MenuItem, ButtonToolbar, ButtonGroup, Button } from 'react-bootstrap';
import ChartLegend from "../components/ChartLegend";
import * as _ from "lodash";

function SelectTimeSpan(props) {

    return(
        <div className="ButtonToolbar">
            <ButtonToolbar>
                <ButtonGroup justified>
                    <Button href="#" bsSize="xsmall" onClick={() => props.setTimeSpan('week')} active={props.timeSpan === "week"}>Last week</Button>
                    <Button href="#" bsSize="xsmall" onClick={() => props.setTimeSpan('month')} active={props.timeSpan === "month"}>Last month</Button>
                    <Button href="#" bsSize="xsmall" onClick={() => props.setTimeSpan('6months')} active={props.timeSpan === "6months"}>Last 6 months</Button>
                </ButtonGroup>
            </ButtonToolbar>
        </div>
    );
}

function UserDropdown(props) {
    const idPrefix = "user-dropdown-";

    return (
        <div className="UserDropdown">
            <DropdownButton
                bsSize="small"
                title= {props.selectedUser || "All accounts and users"}
                id=" {idPrefix}"
            >
                <MenuItem onSelect={() => props.onUserSelected(null)} key ={idPrefix + "all"} className="AllAccounts">All accounts and users</MenuItem>
                {props.users.map(account => {
                    let dropdownMenuItems = [(<MenuItem key={idPrefix + "header" + account.accountId} header>{account.accountId}</MenuItem> )];
                    account.usernames.forEach(username => dropdownMenuItems.push(<MenuItem className="menu-users" onSelect={() => props.onUserSelected(username)} key={idPrefix + username}>{username}</MenuItem>));
                    return dropdownMenuItems;
                })}
            </DropdownButton>
        </div>
    );
}

export default class Dashboard extends Component {
    _isMounted = false;
    queryCounter = 0;
    constructor(props) {
        super(props)
        this.state = {
            "results": [],
            "selectedAccountIds": [],
            "selectedTimeSpan": "month",
            "selectedUser": null,
            "palette": {},
            "err": null
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    ifSelectAll(item) {
        return item === 'all' ? '' : item;
    }

    async fetchResults(filterParameters) {
        try {
            this.queryCounter++;
            let counter = this.queryCounter;
            const results = await API.post("lambda", "/account", { body: {
                accountManager: this.ifSelectAll(filterParameters.accountManager),
                type: this.ifSelectAll(filterParameters.accountType) ,
                textFilter: filterParameters.textFilter,
                onlyEnabled: filterParameters.enableAccount} });
            if (counter !== this.queryCounter) {
                return;
            }
            if (!this._isMounted) {
                return;
            }
            const resultId = results.map(result => result.accountId);
            const selectedAccountIds = this.state.selectedAccountIds.filter(item => resultId.includes(item));
            const selectedUser = this.getUpdatedUserSelection({ results, selectedAccountIds });
            const palette = this.getUpdatedPalette(selectedAccountIds);
            this.setState({ results, selectedAccountIds, selectedUser, palette, err: null });
        } catch(err) {
            this.setState({ err });
        }
    }

    changeAccountIdSelection(selectedAccountIds) {
        const selectedUser = this.getUpdatedUserSelection({ selectedAccountIds });
        const palette = this.getUpdatedPalette(selectedAccountIds);
        this.setState({ selectedAccountIds, selectedUser, palette });
    }

    getUpdatedPalette(selectedAccountIds) {
        const colorPalette = ["#006180","#eda705", "#9dae00", "#008ebc", "#f6b521", "#10b172"]
        const palette = _.reduce(
            selectedAccountIds,
            (memo, accountId, idx) => {
                memo[accountId] =  colorPalette[idx % colorPalette.length];
                return memo;
            }, {});
        return palette;
    }

    getUsersByAccount( { results = this.state.results, selectedAccountIds = this.state.selectedAccountIds } = {}) {
                return results
                .filter(result => selectedAccountIds.includes(result.accountId))
                .map(result => (
                    {
                        accountId: result.accountId,
                        usernames: result.users.map(user => user.username).filter(users => !!users.length)
                    }));
    }

    getUpdatedUserSelection({ results = this.state.results, selectedAccountIds = this.state.selectedAccountIds } = {}) {
        return this.getUsersByAccount({ results, selectedAccountIds })
            .flatMap(user => user.usernames)
            .includes(this.state.selectedUser) ? this.state.selectedUser : null;
    }

    render() {
        return (
            <div className= "container">
                <div className="Dashboard row">
                    <div className="col-sm-3 table-responsive">
                        <AccountFilter
                        fetchResults={filterParameters => this.fetchResults(filterParameters)}
                        />
                        <AccountList
                            err={ this.state.err }
                            results={ this.state.results }
                            selected={ this.state.selectedAccountIds }
                            changeAccountIdSelection={ this.changeAccountIdSelection.bind(this) }
                        />
                    </div>
                    <div className="col-sm-9">
                        <SelectTimeSpan timeSpan={this.state.selectedTimeSpan} setTimeSpan={ selectedTimeSpan => this.setState({selectedTimeSpan}) }/>
                        <ChartLegend accountIds={this.state.selectedAccountIds} style={this.state.chartStyler} palette={this.state.palette}/>
                        <UsageDataTimeline accountIds={this.state.selectedAccountIds} timeSpan={this.state.selectedTimeSpan} palette={this.state.palette}/>
                        <UserDropdown selectedUser={this.state.selectedUser} users={ this.getUsersByAccount() } onUserSelected={ selectedUser => this.setState({selectedUser}) } />
                        <ActivityTimeline accountIds={this.state.selectedAccountIds} timeSpan={this.state.selectedTimeSpan} user={this.state.selectedUser} palette={this.state.palette}/>
                        <ActivityHeatMap accountIds={this.state.selectedAccountIds} timeSpan={this.state.selectedTimeSpan} user={this.state.selectedUser}/>
                    </div>
                </div>
            </div>
        );
    }
}
